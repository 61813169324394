<!-- CSS only -->
<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
  rel="stylesheet"
  integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
  crossorigin="anonymous"
/>
<!--<link rel="stylesheet" href="/onbk/assets/css/theme.css">-->
 <link rel="stylesheet" href="/assets/css/theme.css">

<div align="center" style="width: 100%; height: 100%; background: rgb(112, 111, 111)">
  <div align="center">
    <div class="landingpage-container">
      <header
        [class.display-none]="!display"
        [class.display-anm]="display"
        style="display: {{ display ? '' : 'none' }}"
        role="banner"
        class="c-page-header c-page-header--landing"
        ng-class="{ 'c-page-header--landing': isLanding }"
        style=""
      >
        <div
          ng-if="isLanding"
          class="container c-page-header__container u-nopadding"
        >
          <div class="c-page-header__logo-container">
            <div
              class="c-page-header__logo-container-cell c-page-header__logo-container-cell--landing"
            >
              <a
                class="c-page-header__logo-landing"
                title="BK Banque"
                href="/"
              >
                <img
                  class="img-logo-land-page"
                  height="50"
                  alt="BK Banque"
                  src="assets/img/thumbnail_LOGO-bkb-250x50.png"
                />
              </a>
            </div>

            <div
              class="c-page-header__logo-container-cell c-page-header__logo-container-cell--landing"
            >
              <h1
                style="
                  font: 400 23px/32px Roboto, 'Helvetica Neue', sans-serif;
                  font-weight: bold;
                  color: #888;
                "
              >
                OUVREZ VOTRE
                <strong style="color: var(--boa-blue)"
                  >COMPTE BOURSE EN LIGNE</strong
                >
              </h1>
            </div>
          </div>

          <div class="row" style="float: right">
            <div class="col-sm-7 col-sm-offset-5 text-right"></div>
          </div>
        </div>
      </header>

      <main [class.display-none]="!display" [class.display-anm]="display">
        <div class="page-main">
          
          <div class="landing-page-main-title">
            Ouverture de votre compte bourse en 4 étapes,
            <b class="bold-text">simplement, rapidement </b> et
            <b class="bold-text">100% en ligne</b>
          </div>
          <!--<hr style="background: #fff; height: 2px; width: 50%;
        margin: auto;margin-bottom: 30px;">-->

          <div class="row step-container">
            <div class="col-md-3 col-sm-6 step">
              <div class="text-head-step">
                Dites nous plus sur vous
              </div>
              <div class="icon-head-step c-icon--5min">
                <img
                  src="assets/img/thumbnail_icon-1-B.png"
                  alt=""
                  width="32"
                  height="32"
                />
              </div>
              <div class="description-head-step">
                Justifiez votre identité
              </div>
            </div>
            <div class="col-md-3 col-sm-6 step">
              <div class="text-head-step">
                Justifiez votre identité
              </div>
              <div class="icon-head-step">
                <img
                  src="assets/img/thumbnail_icon2-B.png"
                  alt=""
                  width="32"
                  height="32"
                />
              </div>
              <div class="description-head-step">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div class="col-md-3 col-sm-6 step">
              <div class="text-head-step">Choisissez vos services</div>
              <div class="icon-head-step">
                <img
                  src="assets/img/thumbnail_icon4-b.png"
                  alt=""
                  width="32"
                  height="32"
                />
              </div>
              <div class="description-head-step">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div class="col-md-3 col-sm-6 step">
              <div class="text-head-step">
                Signez vos documents en ligne
              </div>
              <div class="icon-head-step">
                <img
                  src="assets/img/thumbnail_icon3-B.png"
                  alt=""
                  width="32"
                  height="32"
                />
              </div>
              <div class="description-head-step">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
          </div>

          <hr
            style="
              background: #fff;
              height: 2px;
              width: 50%;
              margin: auto;
              margin-top: 70px;
              margin-bottom: 70px;
            "
          />

          <div >
            <div class="col-md-12" [class.loader]="displayButtonOpenAccount">
              <button
                *ngIf="!displayButtonOpenAccount"
                (click)="openSansMandataire()"
                [routerLink]="['/account']"
                type="button"
                class="btn btn-outline-primary land-btn-container land-btn-container-btn1"
              >
                <div class="btn-outlined-icon">
              
              
                  <object style="margin-top: -7.1px" data="assets/img/icons-2022.svg"></object>
                  
                </div>
                <span class="text-btn-outlined">
                  J'OUVRE MON COMPTE BOURSE EN LIGNE
                </span>
              </button>
            </div>
          </div>
        </div>

        <div>




        </div>
<!-- 
        <div class="row eligi-condition-container">
          <div
            class="col-md-6"
            style="height: 100%; background: white; margin-top: 84px"
          >
            <div class="free-item-img-container">
              <img
                class="free-item-img"
                src="assets/img/list-check-(yb).svg"
                alt=""
              />
              Conditions
              <strong>d'éligibilité</strong>
            </div>

            <div class="txt-step-detail">
              <img
                style="height: 30px"
                src="assets/img/check-square-blue.png"
                alt=""
              />
              <p class="detail-item-free">
                Aucune obligation de domicilier vos revenus chez BK Banque.
              </p>
            </div>

            <div class="txt-step-detail">
              <img
                style="height: 30px"
                src="assets/img/check-square-blue.png"
                alt=""
              />
              <p class="detail-item-free">
                Vous effectuez un premier versement pour activer définitivement
                votre compte (cette somme sera créditée sur votre compte une
                fois celui-ci ouvert).
              </p>
            </div>
          </div>
          <div class="col-md-6 img_eligibilite">
            <div
              class="shine-dev"
              style="position: absolute; margin-top: 40px; right: 0; width: 50%"
            >
              <img
                style="height: 520px; width: 100%"
                src="assets/img/conseil_et_ingenierie_financiere_-_2eme_photo.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

      
        <div class="row" style="margin-top: 100px; height: 500px">
          <div class="col-md-6 img-liberte">
            <div
              class="shine-dev"
              style="position: absolute; margin-top: -37px; left: 0; width: 50%"
            >
              <img
                style="height: 520px; width: 100%"
                src="assets/img/conseil_et_ingenierie_financiere_-_3eme_photo.jpg"
                alt=""
              />
            </div>
          </div>

          <div
            class="col-md-6"
            style="
              margin-top: 20px;
              height: 100%;
              opacity: 1;
              background: white;
            "
          >
            <div class="free-item-img-container">
              <img
                class="free-item-img"
                src="assets/img/star-fill.svg"
                alt=""
              />
              Toujours <strong>plus proche</strong> de vous !
            </div>

            <div class="txt-step-detail">
              <img style="height: 30px" src="assets/img/star-fill.svg" alt="" />
              <p class="info-text-detail">
                Résolument tournée vers la digitalisation de ses services et
                processus, BMCE Capital Bourse vous propose une nouvelle
                solution innovante entièrement repensée pour répondre à vos
                besoins les plus pointus, ce service révolutionnant l’ouverture
                de compte, tire parti des nouvelles technologies de pointe pour
                vous garantir un on-boarding complétement digitalisé. Ceci, à
                travers votre identification à distance et une authentification
                sécurisée de vos données personnelles.

                <br />
                <br />
                Ainsi, ouvrir un compte d’intermédiation n’a jamais été aussi
                simple…vous avez désormais la possibilité d’effectuer une
                ouverture de compte à partir de chez vous en quelques clics
                seulement ...
              </p>
            </div>
          </div>
        </div>
 -->
        <!-- <div class="row btm-detail-txt">
          <div class="col-md-12 btm-detail-txt-1">
            <span [routerLink]="['/infos']" class="infosgenerales">
              Informations générales
            </span>

            <p style="padding-top: 5px">© BMCE CAPITAL 2022</p>
          </div>
        </div> -->

        <footer class="b-footer">
          <div class="b-footer__contact">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-3 col-md-6 col-sm-12 b-logo ">
                          <div class="b-footer__contact--bkr">
                              <a target="_blank" href="/">
                                  <img src="assets/img/logo-BKB-250X50.png" alt="bkb" title="bkb">
                              </a>
                          </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12 b-links">
                          <div class="b-footer__contact--contact">
                              <h4 style =" color: white;
                              font-size: 1rem !important;
                              
                              /*margin-left: 19%;*/
                              font-weight: bold;
                              font-family: Roboto, 'Helvetica Neue', sans-serif;">Nos adresses</h4>
                              <ul >
                                  <li class="stylish">63, boulevard Moulay Youssef, 20000 Casablanca - Maroc</li>
                                      <a class="stylish" target="_blank" href="tel:+212 522 42 78 20">
                                          +212 522 42 78 20
                                      </a>
                                  
                                  
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12 b-links">
                          <div >
                              <h4 style =" color: white;
                              font-size: 1rem !important;
                              font-weight: bold;
                              /*margin-left: 19%;*/
                              font-family: Roboto, 'Helvetica Neue', sans-serif;">Notre groupe</h4>
                              <ul>
                                                                                                                                  <li>
                                          <a class="stylish" target="_blank" href="https://www.bmcecapital.com" target="_blank">
                                              BMCE CAPITAL
                                          </a>
                                      </li>
                                                                                                                                  <li>
                                          <a  class="stylish" target="_blank" href="https://www.bmcecapitalbourse.com" target="_blank">
                                              BMCE CAPITAL Bourse
                                          </a>
                                      </li>
                                                                                                                                  <li>
                                          <a class="stylish" target="_blank" href="https://www.bmcecapitalgestion.com" target="_blank">
                                              BMCE CAPITAL Gestion
                                          </a>
                                      </li>
                                    </ul>   
                                    <ul>                                                                                 <li>
                                          <a class="stylish" target="_blank" href="https://www.bmcecapitalgestionprivee.com/" target="_blank">
                                              BMCE CAPITAL Gestion Privée
                                          </a>
                                      </li>
                                                                                                                                  <li>
                                          <a class="stylish" target="_blank" href="http://www.bmcecapitalmarkets.com/" target="_blank">
                                              BMCE CAPITAL Markets
                                          </a>
                                      </li>
                                              </ul>            
                          </div>
                      </div>
                      
                                           <div class="col-lg-3 col-md-6 col-sm-12 b-links">
                              <div class="b-footer__contact--espaceprive" style="">
                                  <div>
                                      <h5 style =" color: white;
                                      font-size: 1rem !important;
                                      
                                      /*margin-left: 19%;*/
                                      font-weight: bold;
                                      font-family: Roboto, 'Helvetica Neue', sans-serif;">BMCE CAPITAL BOURSE</h5>
                                  
                                     
                              
                                  </div>
                                  <div class="d-flex flex-wrap btns">
                                      
                                      <a class="btn btn-transparent-white btn-h40 btn-w20 stylish" [routerLink]="['/account']">
                                          Devenir client
                                      </a>
                                  </div>
                                     
                              </div>
                          </div>
                                      <div class="col-lg-12 col-md-12 b-footer__bottomline">
                          <div class="container">
                              <div class="row">
                                  <div class="col-lg-12 copyright">
                                      <p>&copy; Copyright 2022 - BMCE Capital Bourse  By <a  target="_blank"><b>Pôle SITI - BMCE CAPITAL </b></a> |
                                                                              <a [routerLink]="['/infos']">
                                              Conditions Générales d&#039;utilisation </a> 
                                                                             
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
      </main>
    </div>
  </div>
</div>
